import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import {ISubscriptionPlan} from '../../models/subscriptions-plan/subscription-plan.interface';

export interface IActiveSubscription {
  data?: {
    id: string;
    itemId: string;
    name: string;
    role: string;
    description: string;
    createdAt: Date;
    periodStart: Date;
    periodEnd: Date;
    price: number;
    interval: string;
    priceId: string;
    product: string;
    hasBooking: boolean;
    fromPlatform: boolean; // If the subscription comes from stripe or default assignment
    invoices?: Array<any>;
    cancelAtPeriodEnd?: boolean;
    cancelAt?: Date;
    scheduledPhases?: any;
    limitMaxServices?: number;
    limitMaxAppointments?: number;
  };
  error?: string;
}

export interface SubscriptionsPlanState extends EntityState<ISubscriptionPlan, string> {
  activeSubscriptionsWithoutBooking: Array<IActiveSubscription>;
  activeSubscriptionWithBooking: IActiveSubscription;
}

// Caching is valid for 7 days
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'subscriptions-plan', idKey: 'name', cache: { ttl: 7 * 60 * 60 * 1000 } })
export class SubscriptionsPlanStore extends EntityStore<SubscriptionsPlanState> {
  constructor() {
    super({activeSubscriptionsWithoutBooking: [], activeSubscriptionWithBooking: undefined}) ;
  }
}
