import { HttpClient } from '@angular/common/http';
import { EMPTY, lastValueFrom, shareReplay, Subscription, take, tap, throwError } from 'rxjs';
import { SubscriptionsPlanStore } from '../../stores/subscriptions-plan/subscriptions-plan.store';
import { inject, Injectable } from '@angular/core';
import { addDoc, collection, Firestore, onSnapshot } from '@angular/fire/firestore';
import { checkoutSessionsProviderPath } from '../../config/paths';
import { ISubscriptionPlan } from '../../models/subscriptions-plan/subscription-plan.interface';
import { UserQuery } from '../../stores/user/user.query';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { SubscriptionsPlanQuery } from '../../stores/subscriptions-plan/subscriptions-plan.query';
import { buildApiUrl } from '../../utils/helper';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsPlanService {

  private firestore: Firestore = inject(Firestore);
  private subscriptionsSubscription: Subscription;

  constructor(private http: HttpClient,
              private userQuery: UserQuery,
              private functions: Functions,
              private subscriptionsPlanQuery: SubscriptionsPlanQuery,
              private subscriptionsPlanStore: SubscriptionsPlanStore) { }

  fetchSubscriptionsPlanLogic() {
    return this.subscriptionsPlanQuery.selectHasCache().pipe(
      switchMap((hasCache: boolean) => {
        if (hasCache) {
         //  console.log('hasCache return EMPTY');
          return EMPTY; // If the cache exists, return an empty observable
        } else {
          // If the cache does not exist, make the API call
          this.subscriptionsPlanStore.setLoading(true);
          this.subscriptionsPlanStore.setError(null);
          // console.log('performing call again !');
          return this.http.get(buildApiUrl(`subscriptions-plans`)).pipe(
            take(1),
            tap((res: any) => {
              this.subscriptionsPlanStore.set(res);
              this.subscriptionsPlanStore.setLoading(false);
            }),
            catchError((error: any) => {
              this.subscriptionsPlanStore.setLoading(false);
              this.subscriptionsPlanStore.setError(error);
              return throwError(error);
            })
          );
        }
      }),
      shareReplay(1) // Cache the result and share it among subscribers
    );
  }

  fetchSubscriptionsPlan() {
    if (!this.subscriptionsSubscription) {
      this.subscriptionsSubscription = this.fetchSubscriptionsPlanLogic().subscribe();
    }
    return this.subscriptionsSubscription;
  }

  fetchSubscriptionsPlanLightForProvider() {
    this.userQuery.select()
      .pipe(
        filter(user => !!user.uid),
        take(1),
        switchMap(user =>
          this.http.get(buildApiUrl(`subscriptions/${user.uid}`)).pipe(take(1))
        )
      )
      .subscribe((data: any) => {
        console.log(data);
      });
  }

  fetchSubscriptionsPlanForProvider() {
    this.userQuery.select()
      .pipe(
        filter(user => !!user.uid),
        take(1),
        switchMap(user =>
          this.http.get(buildApiUrl(`providers/subscriptions-plans/${user.uid}`)).pipe(take(1))
        )
      )
      .subscribe((res: any) => {
        // console.log('res', res);
        this.subscriptionsPlanStore.update({
          activeSubscriptionWithBooking: {
            data: res.data ? res.data.activeProviderSubscription : undefined,
          },
          activeSubscriptionsWithoutBooking: res.data ? res.data.activeCustomerSubscriptions : [],
        });
      });
  }

  async startSubscription(subscription: ISubscriptionPlan, priceId: string) {
    // First, invalidate the provider cache
    // await lastValueFrom(this.http.post(buildApiUrl(`invalidate-cache/provider`), {}));
    console.log('Provider cache invalidated');

    onSnapshot(await addDoc(collection(this.firestore, checkoutSessionsProviderPath(this.userQuery.getValue().uid)) as any, <any>{
      price: priceId,
      allow_promotion_codes: true,
      locale: this.userQuery.getValue().selectedLanguage || 'ro',
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    }), (snap) => {
      // Wait for the CheckoutSession to get attached by the extension
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  }

  async redirectToCustomerPortal() {
    const res: any = await httpsCallable(this.functions, 'ext-firestore-stripe-payments-createPortalLink')({
      returnUrl: window.location.origin
    })
    console.log('redirectToCustomerPortal :', res.data.url);
    window.location.assign(res.data.url);
  }

}
