import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import {SubscriptionsPlanState, SubscriptionsPlanStore} from './subscriptions-plan.store';
import {filter, Observable} from 'rxjs';
import {ISubscriptionPlan} from '../../models/subscriptions-plan/subscription-plan.interface';

@Injectable({ providedIn: 'root' })
export class SubscriptionsPlanQuery extends QueryEntity<SubscriptionsPlanState> {
  constructor(protected override store: SubscriptionsPlanStore) {
    super(store);
  }

  selectSubscriptionsWithBooking() {
    return this.selectAll({
      filterBy: ({ hasBooking }) => hasBooking
    });
  }

  selectSubscriptionsWithoutBooking() {
    return this.selectAll({
      filterBy: ({ hasBooking }) => !hasBooking
    });
  }

  getSubscriptionsWithBooking() {
    return this.getValue().activeSubscriptionWithBooking;
  }

  getSusbcriptionsWithoutBooking() {
    return this.getValue().activeSubscriptionsWithoutBooking;
  }

  findSubscriptionPlanByName(name: string): Observable<ISubscriptionPlan> {
    return this.selectEntity(entity => {
      return entity.name === name
    });
  }
}
